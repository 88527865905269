import { useNavigate } from 'react-router-dom';

import { useState, useEffect, useContext } from 'react';
import { DocumentDuplicateIcon, FolderIcon } from '@heroicons/react/24/outline';
import { sortClientsAndProjects } from '../../services/utils/sortClientsAndProjectsUtils';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { EmptyStates } from '../organisms/EmptyStates';
import { PageHeading } from '../molecules/PageHeading';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { ParentCardWithChildList } from '../molecules/ParentCardWithChildList';
import { Searchbar } from '../atoms/Searchbar';
import { useTeam } from '../../contexts/TeamContext';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputColorPicker } from '../molecules/SlideOverInputColorPicker';
import { SlideOverInputCombobox } from '../molecules/SlideOverInputCombobox';
import { SlideOverInputCheckbox } from '../molecules/SlideOverInputCheckbox';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';

export default function PortfolioScreen() {
  /* Access context values using the useTeam hook */
  const { teamUUID, teamName } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const navigate = useNavigate();
  const [openClientSlideOver, setOpenClientSlideOver] = useState(false);
  const [openProjectSlideOver, setOpenProjectSlideOver] = useState(false);
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);
  const [userPrivileges, setUserPrivileges] = useState('member');
  const [clients, setClients] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [searchedClientsResult, setSearchedClientsResult] = useState([]);

  /* BEGINNING CALL API */
  /* Retrieve team client with project associated */
  const fetchTeamClientsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamClientsByTeamUUID',
      navigate,
      params: { teamUUID, setClients, setSearchedClientsResult },
    });
  };

  const fetchTeamBudgetsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamBudgetsByTeamUUID',
      navigate,
      params: { teamUUID, setBudgets },
    });
  };

  const fetchTeamMembersByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: { teamUUID, setPeople: setMembers },
    });
  };

  /* Get team tags */
  const fetTeamTagsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamTagsByTeamUUID',
      navigate,
      params: { teamUUID, setListOfTags: setTags },
    });
  };

  /* ENDING CALL API */

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'Projects');
      fetchTeamClientsByTeamUUIDPromise(teamUUID);
      fetchTeamBudgetsByTeamUUIDPromise(teamUUID);
      fetchTeamMembersByTeamUUIDPromise(teamUUID);
      fetTeamTagsByTeamUUIDPromise(teamUUID);
    }
  }, [teamUUID]);

  const updateOpenClientSlideOver = (newOpenSlideOver) => {
    setOpenClientSlideOver(newOpenSlideOver);
  };

  const updateOpenProjectSlideOver = (newOpenSlideOver) => {
    setOpenProjectSlideOver(newOpenSlideOver);
  };

  const updateSearchedClient = (searchClientName) => {
    const searchLower = searchClientName.toLowerCase();

    // Use map and filter to find and include only the matching projects for each client
    const filteredClients = clients
      .map((client) => {
        // Filter projects that match the search term
        const matchingProjects = client.projects.filter((project) =>
          project.name.toLowerCase().includes(searchLower)
        );

        // Include the client if either the client name matches or there are matching projects
        if (
          client.name.toLowerCase().includes(searchLower) ||
          matchingProjects.length > 0
        ) {
          return {
            ...client,
            projects: matchingProjects,
          };
        }
        return null;
      })
      .filter((client) => client !== null); // Filter out null entries

    setSearchedClientsResult(filteredClients);
  };

  /* BEGINNING CALL API */
  const postTeamProjectsCall = async (inputData, reset) => {
    const params = {
      teamUUID,
      name: inputData.projectName || undefined,
      description: inputData.projectDescription || undefined,
      notes: inputData.projectNotes || undefined,
      color: inputData.projectColor || undefined,
      clientUUID: inputData.projectClient || undefined,
      budgetsUUIDs:
        Object.entries(inputData.projectBudgets)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [budget1, budget2, ...]
      tentative: inputData.projectIsTentative,
      isTagsRequired: inputData.projectIsTagsRequired,
      membersUUID:
        Object.entries(inputData.projectMembers)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [uuid1, uuid2, ...]
      tagsUUIDs:
        Object.entries(inputData.projectTags)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [tag1, tag2, ...]
    };

    await dynamicApiCall({
      callName: 'postTeamProjects',
      navigate,
      params: {
        ...params,
        reset,
        updateOpenSlideOver: updateOpenProjectSlideOver,
        updateOpenNotification,
        updateNotificationData,
        fetchTeamClientsByTeamUUIDPromise,
        fetchTeamBudgetsByTeamUUIDPromise,
      },
    });
  };

  const postTeamClientsCall = async (inputData, reset) => {
    const params = {
      teamUUID,
      name: inputData.clientName || undefined,
      description: inputData.clientDescription || undefined,
      notes: inputData.clientNotes || undefined,
      color: inputData.clientColor || undefined,
    };

    await dynamicApiCall({
      callName: 'postTeamClients',
      navigate,
      params: {
        ...params,
        fetchTeamClientsByTeamUUIDPromise,
        fetchTeamBudgetsByTeamUUIDPromise,
        reset,
        updateOpenSlideOver: updateOpenClientSlideOver,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* END CALL API */
  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'Clients');
      fetchTeamClientsByTeamUUIDPromise(teamUUID);
      fetchTeamBudgetsByTeamUUIDPromise(teamUUID);
    }
  }, [teamUUID, navigate]);

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8">
        <PageHeading
          pages={[
            {
              name: `Portfolio (${
                searchedClientsResult.length
              } clients - ${searchedClientsResult.reduce(
                (acc, client) => acc + client.projects.length,
                0
              )} projects)`,
              href: null,
              current: true,
            },
          ]}
        >
          <Searchbar
            placeholder="Search clients or projects"
            onChange={updateSearchedClient}
          />
          {userPrivileges !== 'member' ? (
            <>
              <PageHeadingButton
                label="Create client"
                onClick={() => setOpenClientSlideOver(true)}
              >
                <FolderIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
              </PageHeadingButton>
              <PageHeadingButton
                label="Create project"
                onClick={() => setOpenProjectSlideOver(true)}
              >
                <DocumentDuplicateIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
              </PageHeadingButton>
            </>
          ) : null}
        </PageHeading>

        {searchedClientsResult.length > 0 ? (
          <ParentCardWithChildList
            data={searchedClientsResult}
            onParentClick={(e) =>
              navigate(`/portfolio/${encodeURIComponent(e.name)}`, {
                state: {
                  initialClient: e,
                },
              })
            }
            onChildClick={(e) => {
              navigate(
                `/portfolio/${encodeURIComponent(
                  e.parent.name
                )}/${encodeURIComponent(e.child.name)}`,
                {
                  state: {
                    initialClient: e.parent,
                    initialProject: e.child,
                  },
                }
              );
            }}
          />
        ) : (
          <div className="h-full flex justify-center items-center">
            <EmptyStates
              displayFor={userPrivileges}
              label="There is no client and no project yet !"
              subLabel="Start by creating a new client."
              buttonLabel="Create client"
              onClick={() => {
                updateOpenClientSlideOver(!openClientSlideOver);
              }}
            >
              <FolderIcon
                className="mx-auto h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
            </EmptyStates>
          </div>
        )}
      </div>

      {/* Client SlideOver */}
      <SlideOver
        open={openClientSlideOver}
        setOpen={updateOpenClientSlideOver}
        title="Create client"
        defaultValues={{
          clientName: null,
          clientDescription: null,
          clientNotes: null,
          clientColor: null,
        }}
      >
        <SlideOverFormContainer onSubmit={postTeamClientsCall}>
          <SlideOverInputContainer>
            <SlideOverInputLabel label="Client name" isRequired />
            <SlideOverInputText name="clientName" isRequired />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Description" />
            <SlideOverInputTextArea name="clientDescription" rows={1} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Notes" />
            <SlideOverInputTextArea name="clientNotes" rows={2} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Color" />
            <SlideOverInputColorPicker name="clientColor" />
          </SlideOverInputContainer>

          <SlideOverHandleButtons submitLabel="Create client" />
        </SlideOverFormContainer>
      </SlideOver>

      {/* Project SlideOver */}
      <SlideOver
        open={openProjectSlideOver}
        setOpen={updateOpenProjectSlideOver}
        title="Create project"
        defaultValues={{
          projectName: null,
          projectClient: null,
          projectDescription: null,
          projectNotes: null,
          projectColor: null,
          projectBudgets: budgets.reduce((acc, budget) => {
            acc[budget.uuid] = false; // Initialize all budgets as not included by default
            return acc;
          }, {}),
          projectIsTentative: false,
          projectMembers: members.reduce((acc, member) => {
            acc[member.uuid] = false; // Initialize all members as not included by default
            return acc;
          }, {}),
          projectTags: tags.reduce((acc, tag) => {
            acc[tag.tagUUID] = false; // Initialize all tags as not included by default
            return acc;
          }, {}),
          projectIsTagsRequired: false,
        }}
      >
        <SlideOverFormContainer onSubmit={postTeamProjectsCall}>
          <SlideOverInputContainer>
            <SlideOverInputLabel label="Project name" isRequired />
            <SlideOverInputText name="projectName" isRequired />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Client" isRequired />
            <SlideOverInputCombobox
              name="projectClient"
              placeholder="Research a client"
              data={clients}
              isRequired
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Budgets"
              subLabel="Amount will be equally splitted between each selected budgets."
              linkData={{ to: `/budgets`, label: `Manage budgets` }}
            />
            <SlideOverInputItemsToggle
              name="projectBudgets"
              data={budgets.map((budget) => ({
                ...budget,
                dataType: 'budgets',
              }))}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Description" />
            <SlideOverInputTextArea name="projectDescription" rows={1} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Notes" />
            <SlideOverInputTextArea name="projectNotes" rows={2} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Color" />
            <SlideOverInputColorPicker name="projectColor" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Tentative" />
            <SlideOverInputCheckbox
              name="projectIsTentative"
              label="Tentative"
              sublabel="If checked, project will be flagged as tentative."
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Members"
              linkData={{ to: `/members`, label: `Manage members` }}
            />
            <SlideOverInputItemsToggle name="projectMembers" data={members} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Tags"
              linkData={{
                to: `/settings/teams/${encodeURIComponent(teamName)}/tags`,
                label: `Manage tags`,
              }}
            />
            <SlideOverInputItemsToggle name="projectTags" data={tags} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Allocation settings"
              subLabel="To enable this option, select at least one tag from Tags."
            />
            <SlideOverInputCheckbox
              name="projectIsTagsRequired"
              label="Required tags"
              sublabel="If selected, tags will be required when a member allocates time to this project."
              dependsOn="projectTags"
            />
          </SlideOverInputContainer>

          <SlideOverHandleButtons submitLabel="Create project" />
        </SlideOverFormContainer>
      </SlideOver>
    </SideBarLayout>
  );
}
