import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ExclamationTriangleIcon,
  FunnelIcon,
  ClockIcon,
  CalendarDaysIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/outline';
import { CategoryFilter } from '../molecules/CategoryFilters';
import { Accordion } from '../molecules/Accordion';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { useTeam } from '../../contexts/TeamContext';
import Calendar from '../organisms/Calendar/Calendar';
import {
  getEndOfMonth,
  getCurrentMonthNumber,
  getStartOfMonth,
  getCurrentYearNumber,
  getStartAndEndOfWeek,
  subtractDays,
  addDays,
} from '../../services/utils/momentUtils';
import { Table } from '../molecules/Table';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputCombobox } from '../molecules/SlideOverInputCombobox';
import { SlideOverInputRange } from '../molecules/SlideOverInputRange';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { handleDragEnd } from '../../services/utils/calendarUtils';
import { ModalHandleButtons } from '../molecules/ModalHandleButtons';
import { Modal } from '../molecules/Modal';
import { SelectMenu } from '../molecules/SelectMenu';
import { EmptyStates } from '../organisms/EmptyStates';

export default function AllocationsScreen() {
  const navigate = useNavigate();
  const { teamUUID } = useTeam();

  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [openAllocationsSlideOver, setOpenAllocationsSlideOver] =
    useState(false);

  const [monthSelected, setMonthSelected] = useState({
    start: getCurrentMonthNumber(),
    end: getCurrentMonthNumber(),
  });
  const [yearSelected, setYearSelected] = useState({
    start: getCurrentYearNumber(),
    end: getCurrentYearNumber(),
  });
  const [startWeekSelected, setStartWeekSelected] = useState(
    getStartAndEndOfWeek().startOfWeek
  );
  const [endWeekSelected, setEndWeekSelected] = useState(
    getStartAndEndOfWeek().endOfWeek
  );
  const [selectedView, setSelectedView] = useState('Month');
  const [selectedTab, setSelectedTab] = useState('Calendar');
  const [days, setDays] = useState([]);
  const [anomalies, setAnomalies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState(null);
  const [tags, setTags] = useState([]);
  const [slideOverDefaultValues, setSlideOverDefaultValues] = useState({
    allocationHours: null,
    allocationForProject: null,
    allocationNotes: null,
    allocationProjectsTags: null,
    from: null,
    to: null,
  });
  const [isDraggingEvent, setIsDraggingEvent] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userView, setUserView] = useState({
    uuid: null,
    name: null,
    memberToAllocateFor: null,
    isEveryoneSelected: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [allocationToUpdateData, setAllocationToUpdateData] = useState(null);
  const [allocationMaxHours, setAllocationMaxHours] = useState(null);
  const [remainingHours, setRemainingHours] = useState(null);
  const [allocationProjectsTags, setAllocationProjectsTags] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState(null);
  const [canAllocate, setCanAllocate] = useState(true);
  const [filtersData, setFiltersData] = useState([]);
  const [isSelectingMultipleDays, setIsSelectingMultipleDays] = useState(false);
  const [history, setHistory] = useState([]);
  const [dependenciesReady, setDependenciesReady] = useState(false);
  const [loadingAnomalies, setLoadingAnomalies] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [filteredHistory, setfilteredHistory] = useState([]);
  const [filteredAnomalies, setFilteredAnomalies] = useState([]);
  const [selectedProjectState, setSelectedProjectState] = useState(null);
  const [isTagRequired, setIsTagRequired] = useState(false);

  const updateAllocationToUpdateData = (data) => {
    setAllocationToUpdateData(data);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const updateIsDraggingEvent = (isDragging) => {
    setIsDraggingEvent(isDragging);
  };

  const updateOpenSlideOver = (newOpenModal) => {
    if (newOpenModal === false) {
      updateAllocationToUpdateData(null);
      setSlideOverDefaultValues({
        allocationHours: null,
        allocationForProject: null,
        allocationNotes: null,
        allocationProjectsTags: null,
        from: null,
        to: null,
      });
      setAllocationMaxHours(null);
      setRemainingHours(null);
      setSelectedProjectState(null); // Reset selectedProjectState
      setIsTagRequired(false); // Reset isTagRequired if needed
    }
    setOpenAllocationsSlideOver(newOpenModal);
  };

  /* When clicking on a day in the calendar */
  const handleClickOnDay = ({ fromDate, toDate, isHoliday }) => {
    /* Open the modal */
    if (isDraggingEvent === false) {
      setSlideOverDefaultValues({
        ...slideOverDefaultValues,
        from: fromDate,
        to: toDate,
      });
      calculateHoursByDate(fromDate, toDate);

      if (isHoliday) {
        setOpenModal(true);
      } else {
        updateOpenSlideOver(true);
      }
    }
  };

  /* Retrieve allocations data + days consolidation for month */
  const fetchDataForMonth = ({ month, year, view, selectedUserUUID }) => {
    const from = getStartOfMonth(month.start, year.start);
    const to = getEndOfMonth(month.end, year.end);
    const selectedViewTemp = view || selectedView;

    /* In Case of user wanting to change position to previous month, but it fails we have to adjust those values */
    /* This case is only available for Month because user can drag element into the previous/next month */
    updateMonthAndYearSelected(from, to);

    fetchAllocations({
      teamUUID,
      from,
      to,
      view: selectedViewTemp,
      selectedUserUUID,
    });
  };

  /* Retrieve allocations data + days consolidation for week */
  const fetchDataForWeek = ({ start, end, view, selectedUserUUID }) => {
    const from = start;
    const to = end;
    const selectedViewTemp = view || selectedView;

    fetchAllocations({
      teamUUID,
      from,
      to,
      view: selectedViewTemp,
      selectedUserUUID,
    });
  };

  /* Reset calendar dates */
  const resetDates = (view) => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();
    setStartWeekSelected(startOfWeek);
    setEndWeekSelected(endOfWeek);

    const todayMonth = getCurrentMonthNumber();
    const todayYear = getCurrentYearNumber();
    setMonthSelected({ start: todayMonth, end: todayMonth });
    setYearSelected({ start: todayYear, end: todayYear });

    if (view === 'Week') {
      fetchDataForWeek({ start: startOfWeek, end: endOfWeek, view });
    } else if (view === 'Month') {
      fetchDataForMonth({
        month: { start: todayMonth, end: todayMonth },
        year: { start: todayYear, end: todayYear },
        view,
      });
    }
  };

  /* When changing view handle cases */
  const handleViewChange = (view) => {
    setSelectedView(view);
    const dateTemp = getStartOfMonth(monthSelected.end, yearSelected.end);
    const dateTempEnd = getEndOfMonth(monthSelected.end, yearSelected.end);

    if (view === 'Week') {
      const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(dateTemp);

      setStartWeekSelected(startOfWeek);
      setEndWeekSelected(endOfWeek);

      updateMonthAndYearSelected(startOfWeek, endOfWeek);

      fetchDataForWeek({
        start: startOfWeek,
        end: endOfWeek,
        view,
      });
    } else if (view === 'Month') {
      updateMonthAndYearSelected(dateTemp, dateTempEnd);

      fetchDataForMonth({
        month: {
          start: monthSelected.end,
          end: monthSelected.end,
        },
        year: {
          start: yearSelected.end,
          end: yearSelected.end,
        },
        view,
      });
    }
  };
  /* When navigating through calendar keep the state updated */
  const updateMonthAndYearSelected = (startDate, endDate) => {
    let isMonthChanging = false;
    let isYearChanging = false;
    let currentMonth = null;
    let currentYear = null;

    for (let i = startDate; i <= endDate; i = addDays(i, 1)) {
      const year = parseInt(i.split('-')[0]);
      const month = parseInt(i.split('-')[1]);

      if (currentMonth === null) {
        /* Set the initial value for currentMonth and currentYear */
        currentMonth = month;
        currentYear = year;
      } else if (month !== currentMonth || year !== currentYear) {
        /* The month or year has changed */
        isMonthChanging = true;
        isYearChanging = true;
        setMonthSelected({ start: currentMonth, end: month });
        setYearSelected({ start: currentYear, end: year });
        break; /* No need to continue iterating */
      }
    }

    if (!isMonthChanging && !isYearChanging && currentMonth !== null) {
      /* If the loop completes without detecting a month or year change, */
      /* set the monthSelected and yearSelected end to the last month and year */
      setMonthSelected({ start: currentMonth, end: currentMonth });
      setYearSelected({ start: currentYear, end: currentYear });
    }
  };
  /* Show previous month and update state + data */
  const handlePreviousMonth = () => {
    const newMonth = monthSelected.start === 1 ? 12 : monthSelected.start - 1;
    const newYear =
      monthSelected.start === 1 ? yearSelected.start - 1 : yearSelected.start;

    setMonthSelected({ start: newMonth, end: newMonth });
    setYearSelected({ start: newYear, end: newYear });

    fetchDataForMonth({
      month: { start: newMonth, end: newMonth },
      year: { start: newYear, end: newYear },
      view: selectedView,
    });
  };
  /* Show next month and update state + data */
  const handleNextMonth = () => {
    const newMonth = monthSelected.start === 12 ? 1 : monthSelected.start + 1;
    const newYear =
      monthSelected.start === 12 ? yearSelected.start + 1 : yearSelected.start;

    setMonthSelected({ start: newMonth, end: newMonth });
    setYearSelected({ start: newYear, end: newYear });

    fetchDataForMonth({
      month: { start: newMonth, end: newMonth },
      year: { start: newYear, end: newYear },
      view: selectedView,
    });
  };
  /* Show previous week and update state + data */
  const handlePreviousWeek = () => {
    const newStartDate = subtractDays(startWeekSelected, 7);
    const newEndDate = subtractDays(endWeekSelected, 7);

    setStartWeekSelected(newStartDate);
    setEndWeekSelected(newEndDate);

    updateMonthAndYearSelected(newStartDate, newEndDate);

    fetchDataForWeek({ start: newStartDate, end: newEndDate });
  };

  /* Show next week and update state + data */
  const handleNextWeek = () => {
    const newStartDate = addDays(startWeekSelected, 7);
    const newEndDate = addDays(endWeekSelected, 7);

    setStartWeekSelected(newStartDate);
    setEndWeekSelected(newEndDate);

    updateMonthAndYearSelected(newStartDate, newEndDate);

    fetchDataForWeek({ start: newStartDate, end: newEndDate });
  };

  /* When dragging an event in the calendar, save it into our db */
  const onDragEnd = async (result) => {
    updateIsDraggingEvent(false);
    const handleDragEndData = handleDragEnd({ result, days });

    if (handleDragEndData && handleDragEndData.draggedEvent) {
      /*  Save position of each events within the day of dragged element */
      const targetDate = handleDragEndData.draggedEvent.date;
      const targetDay = days.find((day) => day.date === targetDate);
      if (targetDay && targetDay.events.length > 0) {
        /* Identify the moved event */
        const movedEvent = targetDay.events.find(
          (event) => event === handleDragEndData.draggedEvent
        );

        if (movedEvent) {
          /* Remove the moved event from its current position in the array (not his position object) */
          const eventsToUpdate = targetDay.events.filter(
            (event) => event !== movedEvent
          );

          /* Insert the moved event at the beginning of eventsToUpdate */
          eventsToUpdate.unshift(movedEvent);
          /* Perform the call, so the moved event will always be performed first */
          await putAllocationsCall(null, false, eventsToUpdate);
        }
      }
    }
  };

  const isAbleToAllocateForAnotherUser = (userViewUUID) => {
    if (userViewUUID === user.uuid) {
      setCanAllocate(true);
    } else if (userViewUUID !== user.uuid) {
      if (userPrivileges === 'member') {
        setCanAllocate(false);
      } else {
        setCanAllocate(true);
      }
    }
  };

  /* When clicking on any event */
  const onEventClick = ({ event, day }) => {
    fetchTeamProjectsTags({
      teamUUID,
      projectUUID: event.projectUUID,
    });

    calculateHoursByDate(day.date, day.date);

    updateAllocationToUpdateData({
      allocationDateStart: event.date,
      allocationDateEnd: event.date,
      allocationHours: event.hours,
      allocationMaxHours: event.allocationMaxHours,
      projectUUID: event.projectUUID,
      notes: event.notes,
      position: event.position,
      allocationUUID: event.allocationUUID,
      projectTagUUID: event.projectTagUUID,
    });

    setSlideOverDefaultValues({
      ...slideOverDefaultValues,
      allocationHours: event.hours,
      allocationForProject: event.projectUUID,
      allocationNotes: event.notes,
      allocationProjectsTags: event.projectTagUUID,
      from: event.date,
      to: event.date,
    });

    // Find the project corresponding to event.projectUUID
    const selectedProject = projects.find(
      (project) => project.uuid === event.projectUUID
    );

    if (selectedProject) {
      setSelectedProjectState(selectedProject);
    }

    // Open modal
    updateOpenSlideOver(true);
  };

  const handleAnomaliesClick = (anomaly) => {
    if (canAllocate) {
      const maxHours = anomaly.missing + anomaly.clocked;
      const remainingHours = anomaly.missing;

      setSlideOverDefaultValues({
        ...slideOverDefaultValues,
        from: anomaly.date,
        to: anomaly.date,
      });

      setIsSelectingMultipleDays(false);
      setAllocationMaxHours(maxHours);
      setRemainingHours(remainingHours);
      setUserView({
        ...userView,
        memberToAllocateFor: anomaly.member,
      });
      updateOpenSlideOver(true);
    }
  };

  /* Handle date filters change and add object dates */
  const handleDateFiltersChange = (from, to) => {
    if (!from && !to) {
      return;
    }

    // Update the filtersData with the new date range entry
    setFiltersData((prevFiltersData) => {
      // Check if date range already exists
      const dateRangeIndex = prevFiltersData.findIndex(
        (filter) => filter.id === 'dateRange'
      );

      // Update existing date range
      const updatedFiltersData = [...prevFiltersData];
      updatedFiltersData[dateRangeIndex] = {
        ...updatedFiltersData[dateRangeIndex],
        options: [
          {
            from,
            to,
          },
        ],
      };

      return updatedFiltersData;
    });

    /* Fetch anomalies for the new date range */
    fetchAnomalies({
      teamUUID,
      from,
      to,
    });

    if (selectedTab === 'History') {
      /* Fetch history for the new date range */
      fetchHistory({
        teamUUID,
        from,
        to,
      });
    }
  };

  /* Get team members allocations and data days */
  const fetchAllocations = async ({
    teamUUID,
    from,
    to,
    view,
    returnData = false,
    onlyDays = false,
    selectedUserUUID,
  }) => {
    let tempUser = userView ? userView.uuid : user?.uuid;

    if (selectedUserUUID) {
      tempUser = selectedUserUUID;
    }

    const data = await dynamicApiCall({
      callName: 'getTeamMembersAllocations',
      navigate,
      params: {
        teamUUID,
        from,
        to,
        returnData,
        view,
        setDays,
        onlyDays,
        userUUID: tempUser,
      },
    });

    setLoadingAnomalies(false);
    setLoadingHistory(false);

    return data;
  };

  /* Get team members anomalies */
  const fetchAnomalies = async ({
    teamUUID,
    from = filtersData.find((filter) => filter.id === 'dateRange')?.options[0]
      ?.from,
    to = filtersData.find((filter) => filter.id === 'dateRange')?.options[0]
      ?.to,
    userUUID = userView.uuid,
    selectAllMembers = userPrivileges !== 'member' &&
      userView.isEveryoneSelected,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamMembersAnomalies',
      navigate,
      params: {
        teamUUID,
        from,
        to,
        setAnomalies,
        setLoadingAnomalies,
        userUUID,
        selectAllMembers,
      },
    });
  };

  /* Get team members history */
  const fetchHistory = async ({
    teamUUID,
    from = filtersData.find((filter) => filter.id === 'dateRange')?.options[0]
      ?.from,
    to = filtersData.find((filter) => filter.id === 'dateRange')?.options[0]
      ?.to,
    userUUID = userView.uuid,
    selectAllMembers = userPrivileges !== 'member' &&
      userView.isEveryoneSelected,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamMembersHistory',
      navigate,
      params: {
        teamUUID,
        from,
        to,
        setHistory,
        setLoadingHistory,
        userUUID,
        selectAllMembers,
      },
    });
  };

  /* Get team project user can allocate also used for filters */
  const fetchTeamProjects = async ({ teamUUID, userUUID }) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        userUUID,
        setProjects,
      },
    });
  };

  /* Get user info call */
  const fetchUsers = async () => {
    await dynamicApiCall({
      callName: 'getUsersByAuthToken',
      params: {
        setUserData: setUser,
      },
    });
  };

  /* Get team project tag when selection a project */
  const fetchTeamProjectsTags = async ({ teamUUID, projectUUID }) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsTags',
      navigate,
      params: {
        teamUUID,
        projectUUID,
        setTags,
      },
    });
  };

  /* Get all members */
  const fetchTeamMembers = async ({ teamUUID }) => {
    const rawData = true;
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: {
        teamUUID,
        rawData,
        setPeople: setTeamMembers,
      },
    });
  };

  /* Save allocation */
  const postAllocationsCall = async (inputData, reset) => {
    const params = {
      allocationDateStart: inputData?.from,
      allocationDateEnd: inputData?.to,
      allocationHours: inputData.allocationHours || allocationMaxHours,
      projectUUID: inputData.allocationForProject,
      teamUUID,
      /* userView.allocateFor is defined in case when admin click on anomaly of any user */
      allocateForUser: userView?.memberToAllocateFor?.uuid || userView.uuid,
      projectTagUUID: allocationProjectsTags,
      notes: inputData.allocationNotes,
    };

    await dynamicApiCall({
      callName: 'postTeamMembersAllocations',
      params: {
        ...params,
        updateOpenNotification,
        updateNotificationData,
        updateOpenSlideOver,
        reset,
        view: selectedView,
        fetchAnomalies,
        monthSelected,
        yearSelected,
        startWeekSelected,
        endWeekSelected,
        fetchDataForWeek,
        fetchDataForMonth,
      },
    });
  };

  /* Update allocation */
  const putAllocationsCall = async (inputData, reset, eventsToUpdate) => {
    let params = {};

    /* If updating position update all events */
    if (eventsToUpdate) {
      const promises = [];
      for (const event of eventsToUpdate) {
        params = {
          allocationDateStart: event.date,
          allocationDateEnd: event.date,
          allocationHours: event.hours,
          projectUUID: event.projectUUID,
          teamUUID,
          position: event.position,
          allocateForUser: userView.uuid,
          projectTagUUID: event.projectTagUUID,
          notes: event.notes,
          allocationUUID: event.allocationUUID,
        };

        promises.push(
          await dynamicApiCall({
            callName: 'putTeamMembersAllocations',
            params: {
              ...params,
              updateOpenNotification,
              updateNotificationData,
              updateOpenSlideOver,
              reset,
              monthSelected,
              yearSelected,
              startWeekSelected,
              fetchAnomalies,
              endWeekSelected,
              view: selectedView,
              fetchDataForWeek,
              fetchDataForMonth,
              returnData: true,
            },
          })
        );
      }

      const results = await Promise.all(promises);

      /* Check if all results are defined */
      const allResultsDefined = results.every((result) => result !== undefined);

      if (allResultsDefined === true) {
        updateOpenNotification(true);
        updateNotificationData({
          ...results[0],
        });

        fetchAnomalies({
          teamUUID,
        });

        if (selectedView === 'Week') {
          fetchDataForWeek({
            start: startWeekSelected,
            end: endWeekSelected,
            view: selectedView,
          });
        } else if (selectedView === 'Month') {
          fetchDataForMonth({
            month: monthSelected,
            year: yearSelected,
            view: selectedView,
          });
        }
      }
    } else {
      /* Updating just one event (not dragging) */
      params = {
        allocationDateStart: inputData?.from,
        allocationDateEnd: inputData?.to,
        allocationHours:
          inputData.from !== inputData.to
            ? allocationMaxHours
            : inputData.allocationHours ?? allocationMaxHours,
        projectUUID: inputData.allocationForProject,
        teamUUID,
        allocateForUser: userView.uuid,
        projectTagUUID:
          /* If the member change the allocation project, you check if the project as changed. If so, then you check if there is a new tag filled, if not you send "null" to reset it, if yes you send the new tag */
          inputData.allocationForProject !== allocationToUpdateData.projectUUID
            ? inputData.allocationProjectsTags ===
              allocationToUpdateData.projectTagUUID
              ? null
              : inputData.allocationProjectsTags
            : /* if allocationProjectsTags is null then you send the allocationToUpdataData instead */
              allocationProjectsTags || allocationToUpdateData.projectTagUUID,
        notes: inputData.allocationNotes,
        allocationUUID: allocationToUpdateData.allocationUUID,
      };

      await dynamicApiCall({
        callName: 'putTeamMembersAllocations',
        params: {
          ...params,
          updateOpenNotification,
          updateNotificationData,
          updateOpenSlideOver,
          updateAllocationToUpdateData,
          reset,
          userView,
          monthSelected,
          yearSelected,
          fetchAnomalies,
          startWeekSelected,
          endWeekSelected,
          view: selectedView,
          fetchDataForWeek,
          fetchDataForMonth,
          returnData: false,
        },
      });
    }
  };

  /* Check if user can access this page */
  const getPermissions = async ({ teamUUID, page }) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Retrieve filters data */
  const fetchFiltersData = async () => {
    await dynamicApiCall({
      callName: 'getTeamMembersAllocationsFilters',
      navigate,
      params: {
        teamUUID,
        setFiltersData,
      },
    });
  };

  const shouldTagBeRequired = (selectedProject) => {
    if (selectedProject.isTagsRequired && tags.length > 0) {
      setIsTagRequired(true);
    } else {
      setIsTagRequired(false);
    }
  };

  useEffect(() => {
    if (selectedProjectState) {
      shouldTagBeRequired(selectedProjectState);
    }
  }, [tags, selectedProjectState]);

  /* Reset states when teamUUID changes */
  useEffect(() => {
    if (teamUUID) {
      setUser(null);
      setUserPrivileges(null);
      setDependenciesReady(false); // Reset the flag
      setUserView({
        uuid: null,
        name: null,
        memberToAllocateFor: null,
        isEveryoneSelected: false,
      });
      setAnomalies([]);
      setHistory([]);
    }
  }, [teamUUID]);

  /* Set dependenciesReady flag when user and userPrivileges are set */
  useEffect(() => {
    if (user && userPrivileges) {
      setDependenciesReady(true);
    }
  }, [user, userPrivileges]);

  const isReady =
    userPrivileges &&
    user &&
    teamUUID &&
    dependenciesReady &&
    filtersData.length > 0;

  /* At start of page, fetch data since month is default view */
  useEffect(() => {
    if (teamUUID) {
      getPermissions({ teamUUID, page: 'Allocations' });
      fetchFiltersData();
      fetchUsers();
      fetchTeamMembers({ teamUUID });
    }
  }, [teamUUID]);

  useEffect(() => {
    if (isReady) {
      const from = filtersData.find((filter) => filter.id === 'dateRange')
        ?.options[0]?.from;
      const to = filtersData.find((filter) => filter.id === 'dateRange')
        ?.options[0]?.to;

      fetchAnomalies({
        teamUUID,
        from,
        to,
        userUUID: user.uuid,
        selectAllMembers: userPrivileges !== 'member',
      });

      if (userPrivileges !== 'member') {
        setSelectedTab('Anomalies');
        setUserView({
          uuid: user.uuid,
          name: `${user.firstName} ${user.lastName}`,
          isEveryoneSelected: true,
        });
      } else if (userPrivileges === 'member') {
        setSelectedTab('Calendar');

        setUserView({
          uuid: user.uuid,
          name: `${user.firstName} ${user.lastName}`,
          isEveryoneSelected: false,
        });

        if (selectedView === 'Month') {
          fetchDataForMonth({
            month: monthSelected,
            year: yearSelected,
            view: selectedView,
            selectedUserUUID: user.uuid,
          });
        }
      }
      fetchTeamProjects({ teamUUID, userUUID: user.uuid });
    }
  }, [isReady]);

  /* Whenever the allocationDateStart, allocationDateEnd or days change, update the total max hours and remaining hours */
  const calculateHoursByDate = (from, to) => {
    const allocationDateStart = from;
    const allocationDateEnd = to;

    if (allocationDateStart && allocationDateEnd && days.length > 0) {
      setIsSelectingMultipleDays(allocationDateStart !== allocationDateEnd);

      /* Filter the days to only include the selected period */
      let reachedEndDate = false;
      const filteredData = [];
      let totalMaxHours = 0;
      let remainingHoursLeft = 0;

      for (const item of days) {
        if (
          item.date >= allocationDateStart &&
          item.date <= allocationDateEnd
        ) {
          filteredData.push(item);
          /* Summing maxHours for those days */
          totalMaxHours += item.maxHours;
          /* Summing remainingHours for those days */
          remainingHoursLeft += item.remainingHours;
          if (item.date === allocationDateEnd) {
            reachedEndDate = true;
            break; // Exit the loop early if the end date is found
          }
        }
      }

      setAllocationMaxHours(totalMaxHours);
      setRemainingHours(remainingHoursLeft);

      /* If we haven't reached the end date, we need to fetch more data */
      if (!reachedEndDate) {
        /* Fetch data for the selected period */
        fetchAllocations({
          teamUUID,
          from,
          to,
          view: selectedView,
          returnData: true,
          onlyDays: true,
        }).then((result) => {
          const { days } = result;

          let totalMaxHours = 0;
          let remainingHoursLeft = 0;

          for (const item of days) {
            if (
              item.date >= allocationDateStart &&
              item.date <= allocationDateEnd
            ) {
              totalMaxHours += item.maxHours;
              remainingHoursLeft += item.remainingHours;
            }
          }

          setAllocationMaxHours(totalMaxHours);
          setRemainingHours(remainingHoursLeft);
        });
      }
    }
  };

  /* Listen filters by tag/projetct called when days are fetched and filtersData modified */
  const filtersDays = (selectedTabName) => {
    const currentSelectedTabName = selectedTabName || selectedTab;

    /* If user can filter */
    if (filtersData.length > 0) {
      const unselectedProject = [];
      const unselectedTags = [];
      const unselectedMembers = [];
      let from = null;
      let to = null;

      filtersData.forEach((filter) => {
        if (filter.id === 'projects') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedProject.push(option.uuid)
              : null
          );
        }
        if (filter.id === 'tags') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedTags.push(option.uuid)
              : null
          );
        }
        if (filter.id === 'dateRange') {
          from = filter.options[0].from;
          to = filter.options[0].to;
        }
        if (filter.id === 'members') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedMembers.push(option.uuid)
              : null
          );
        }
      });

      const filteredData = anomalies.map((element) => {
        const memberUUID = element.member.uuid;
        const isWithinDateRange =
          (from ? element.date >= from : true) &&
          (to ? element.date <= to : true);
        const isMemberUnselected = unselectedMembers.includes(memberUUID);

        if (!isWithinDateRange || isMemberUnselected) {
          return { ...element, isVisible: false };
        }

        return { ...element, isVisible: true };
      });

      setFilteredAnomalies(filteredData);

      if (currentSelectedTabName === 'History') {
        const filteredData = history.map((element) => {
          const projectUUID = element.project.uuid;
          const tagUUIDs = element.tags.map((tag) => tag.uuid);
          const memberUUID = element.member.uuid;

          const isProjectUnselected = unselectedProject.includes(projectUUID);
          const isAnyTagUnselected = tagUUIDs.some((tagUUID) =>
            unselectedTags.includes(tagUUID)
          );
          const isMemberUnselected = unselectedMembers.includes(memberUUID);

          const isWithinDateRange =
            (from ? element.date >= from : true) &&
            (to ? element.date <= to : true);

          if (
            isMemberUnselected ||
            isProjectUnselected ||
            isAnyTagUnselected ||
            !isWithinDateRange
          ) {
            return { ...element, isVisible: false };
          }

          return { ...element, isVisible: true };
        });

        setfilteredHistory(filteredData);
      }
    }
  };

  /* Call filtersDays whenever filtersData changes */
  useEffect(() => {
    if (filtersData) {
      filtersDays();
    }
  }, [filtersData, history, anomalies]);

  const tabs = [
    {
      name: 'Calendar',
      icon: <CalendarDaysIcon className="h-5 w-5" />,
      current: selectedTab === 'Calendar',
      tooltip: 'See calendar',
    },
    {
      name: 'Anomalies',
      icon: <ExclamationTriangleIcon className="h-5 w-5" />,
      current: selectedTab === 'Anomalies',
      tooltip: 'See anomalies',
      notifications: filteredAnomalies.filter((anomaly) => anomaly.isVisible)
        .length,
    },
    {
      name: 'History',
      icon: <ClockIcon className="h-5 w-5" />,
      current: selectedTab === 'History',
      tooltip: 'See history',
    },
  ];

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8 flex flex-col">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1 flex items-center justify-between">
            <SingleScreenTitle text="Allocations" />
            <div className="ml-auto flex items-center">
              {userView?.uuid !== user?.uuid && (
                <div className="tooltip">
                  <button
                    type="button"
                    onClick={() => {
                      setUserView({
                        uuid: user.uuid,
                        name: `${user.firstName} ${user.lastName}`,
                        isEveryoneSelected: false,
                      });

                      isAbleToAllocateForAnotherUser(user.uuid);

                      fetchAnomalies({
                        teamUUID,
                        userUUID: user.uuid,
                      });

                      if (selectedView === 'Month') {
                        fetchDataForMonth({
                          month: monthSelected,
                          year: yearSelected,
                          view: selectedView,
                          selectedUserUUID: user.uuid,
                        });
                      }
                    }}
                    className="mr-2"
                  >
                    <ArrowUturnLeftIcon className="h-4 w-4 text-indigo-400 hover:text-indigo-600" />
                  </button>
                  <span className="tooltip-text">Go to your view</span>
                </div>
              )}

              <SelectMenu
                value={
                  userView.isEveryoneSelected
                    ? { isEveryoneSelected: true }
                    : userView.uuid || ''
                }
                showSelectEveryone={userPrivileges !== 'member'}
                data={teamMembers}
                onChange={(value) => {
                  if (value?.isEveryoneSelected) {
                    setUserView({
                      ...userView,
                      isEveryoneSelected: true,
                    });

                    isAbleToAllocateForAnotherUser(user.uuid);

                    fetchAnomalies({
                      teamUUID,
                      selectAllMembers: true,
                    });

                    fetchHistory({
                      teamUUID,
                      selectAllMembers: true,
                    });
                  } else {
                    const selectedUserUUID = value.userUUID;
                    const selectedUser = teamMembers.find(
                      (member) => member.userUUID === selectedUserUUID
                    );
                    /* Check if user is able to allocate for another user and set state */
                    isAbleToAllocateForAnotherUser(selectedUserUUID);

                    setUserView({
                      uuid: selectedUserUUID,
                      name: `${selectedUser.userFirstName} ${selectedUser.userLastName}`,
                      isEveryoneSelected: false,
                    });

                    fetchAnomalies({
                      teamUUID,
                      userUUID: selectedUserUUID,
                      selectAllMembers: false,
                    });

                    fetchHistory({
                      teamUUID,
                      userUUID: selectedUserUUID,
                      selectAllMembers: false,
                    });

                    if (selectedView === 'Month') {
                      fetchDataForMonth({
                        month: monthSelected,
                        year: yearSelected,
                        view: selectedView,
                        selectedUserUUID,
                      });
                    }
                  }
                }}
                displayKey="userFirstName userLastName"
                compareKey="userUUID"
                placeholder="Select a member"
              />
            </div>
            <div className="flex items-center ml-2 rounded-md bg-white md:items-stretch">
              {tabs.map((tab, index) => (
                <div key={tab.name} className="tooltip">
                  <button
                    type="button"
                    id={`allocationsTab${tab.name}`}
                    onClick={() => {
                      setSelectedTab(tab.name);

                      if (tab.name === 'Calendar') {
                        if (selectedView === 'Month') {
                          fetchDataForMonth({
                            month: monthSelected,
                            year: yearSelected,
                            view: selectedView,
                            selectedUserUUID: userView.uuid,
                          });
                        }
                      } else if (tab.name === 'History') {
                        fetchHistory({
                          teamUUID,
                          userUUID: userView.uuid,
                        });
                      } else if (tab.name === 'Anomalies') {
                        fetchAnomalies({
                          teamUUID,
                          userUUID: userView.uuid,
                        });
                      }
                    }}
                    className={`flex h-9 items-center justify-center px-4 border text-gray-600 focus:relative ${
                      index === 0 ? 'rounded-l-md' : ''
                    } ${index === tabs.length - 1 ? 'rounded-r-md' : ''} ${
                      tab.current
                        ? 'bg-indigo-100 text-indigo-600 border-indigo-500'
                        : 'hover:bg-gray-100'
                    }`}
                  >
                    {tab.icon}
                    {tab.notifications ? (
                      <span
                        className={`
                        ${
                          tab.current
                            ? 'bg-red-100 text-red-900'
                            : 'bg-red-100 text-red-900'
                        }
                          px-2 ml-2 rounded-full py-0.5 text-xs font-medium`}
                      >
                        {tab.notifications}
                      </span>
                    ) : null}
                  </button>
                  <span className="tooltip-text">{tab.tooltip}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selectedTab === 'Anomalies' && (
          <>
            <Accordion
              title="Filters"
              className="text-indigo-900 bg-indigo-100 rounded-lg"
              icon={<FunnelIcon className="w-5 h-5 mr-1 text-indigo-900" />}
            >
              <CategoryFilter
                initialFromDate={
                  filtersData.find((filter) => filter.id === 'dateRange')
                    ?.options[0]?.from
                }
                initialToDate={
                  filtersData.find((filter) => filter.id === 'dateRange')
                    ?.options[0]?.to
                }
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                onCalendarDateChange={(from, to) => {
                  handleDateFiltersChange(from, to);
                }}
              />
            </Accordion>
            <div className="mt-4" />
            <div className="rounded-md my-2 overflow-scroll bg-red-50 border-2 border-red-400/75 p-4">
              {loadingAnomalies ? (
                <p className="text-center text-gray-500">
                  Loading anomalies...
                </p>
              ) : (
                <Table
                  tableLabel={`Anomalies (${
                    filteredAnomalies.filter((anomaly) => anomaly.isVisible)
                      .length
                  })`}
                  tableHeads={{
                    member: 'Members',
                    dates: 'Dates',
                    error: 'Errors',
                    clocked: 'Hours already allocated',
                    missing: 'Missing hours',
                    actionLabel: 'Review',
                  }}
                  tableData={filteredAnomalies}
                  onClick={(e) => handleAnomaliesClick(e)}
                  trClassName="hover:bg-red-100 cursor-pointer"
                />
              )}
            </div>
          </>
        )}

        {selectedTab === 'History' && (
          <>
            <Accordion
              title="Filters"
              className="text-indigo-900 bg-indigo-100 rounded-lg"
              icon={<FunnelIcon className="w-5 h-5 mr-1 text-indigo-900" />}
            >
              <CategoryFilter
                initialFromDate={
                  filtersData.find((filter) => filter.id === 'dateRange')
                    ?.options[0]?.from
                }
                initialToDate={
                  filtersData.find((filter) => filter.id === 'dateRange')
                    ?.options[0]?.to
                }
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                onCalendarDateChange={(from, to) => {
                  handleDateFiltersChange(from, to);
                }}
              />
            </Accordion>
            <div className="mt-4" />
            <div className="rounded-md my-2 overflow-scroll bg-gray-50 border-2 border-gray-400/75 p-4">
              {loadingHistory ? (
                <p className="text-center text-gray-500">Loading history...</p>
              ) : (
                <Table
                  tableLabel="History"
                  tableHeads={{
                    dates: 'Dates',
                    durations: 'Hours',
                    projects: 'Projects',
                    notes: 'Notes',
                    member: 'Member',
                    tags: 'Tags',
                    actionLabel: 'Edit',
                  }}
                  tableData={filteredHistory}
                  trClassName="hover:bg-slate-100 cursor-pointer"
                />
              )}
            </div>
          </>
        )}

        {selectedTab === 'Calendar' && userView.isEveryoneSelected && (
          <div className="h-full flex justify-center items-center">
            <EmptyStates
              displayFor={userPrivileges}
              label="Calendar is not available for multiple members."
              subLabel="Please select a specific user to view his calendar."
              shouldDisplayButton={false}
            >
              <ExclamationTriangleIcon
                className="mx-auto h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
            </EmptyStates>
          </div>
        )}
        {selectedTab === 'Calendar' && !userView.isEveryoneSelected && (
          <>
            <div className="mt-4" />
            <Calendar
              days={days}
              resetDates={resetDates}
              monthSelected={monthSelected}
              yearSelected={yearSelected}
              handleNextMonth={handleNextMonth}
              handlePreviousMonth={handlePreviousMonth}
              handleViewChange={handleViewChange}
              selectedView={selectedView}
              handlePreviousWeek={handlePreviousWeek}
              handleNextWeek={handleNextWeek}
              handleClickOnDay={handleClickOnDay}
              updateIsDraggingEvent={updateIsDraggingEvent}
              isDraggingEvent={isDraggingEvent}
              onDragEnd={onDragEnd}
              updateOpenModal={updateOpenModal}
              onEventClick={onEventClick}
              canAllocate={canAllocate}
              fetchDataForWeek={fetchDataForWeek}
              fetchDataForMonth={fetchDataForMonth}
              startWeekSelected={startWeekSelected}
              endWeekSelected={endWeekSelected}
              updateOpenSlideOver={updateOpenSlideOver}
            />
          </>
        )}
      </div>

      {/* Modal POPUP create allocation */}
      <SlideOver
        open={openAllocationsSlideOver}
        setOpen={updateOpenSlideOver}
        title={`Allocations${
          userView?.memberToAllocateFor
            ? ` for ${userView.memberToAllocateFor.name}`
            : ''
        }`}
        defaultValues={slideOverDefaultValues}
      >
        <SlideOverFormContainer
          onSubmit={
            allocationToUpdateData ? putAllocationsCall : postAllocationsCall
          }
        >
          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Period"
              subLabel={
                allocationMaxHours ? `(${allocationMaxHours} hours)` : null
              }
              isRequired
            />

            <CalendarPicker
              isRequired
              onChange={(from, to) => {
                calculateHoursByDate(from, to);
              }}
              name1="from"
              name2="to"
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Projects" isRequired />
            <SlideOverInputCombobox
              name="allocationForProject"
              placeholder="Research a project"
              data={projects}
              isRequired
              customFunction={(selectedProject) => {
                setAllocationProjectsTags(null);
                setSelectedProjectState(selectedProject);
                fetchTeamProjectsTags({
                  teamUUID,
                  projectUUID: selectedProject.uuid,
                });
              }}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Hours"
              subLabel={
                remainingHours !== null ? `(${remainingHours} remaining)` : null
              }
              isRequired={!isSelectingMultipleDays}
            />

            <SlideOverInputRange
              name="allocationHours"
              isRequired={!isSelectingMultipleDays}
              maxNumber={
                allocationToUpdateData
                  ? allocationToUpdateData.allocationHours + remainingHours
                  : remainingHours
              }
              disabled={isSelectingMultipleDays}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Notes" />
            <SlideOverInputTextArea name="allocationNotes" rows={2} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Tags" isRequired={isTagRequired} />
            <SlideOverInputCombobox
              name="allocationProjectsTags"
              placeholder="Research a tag"
              data={tags}
              isRequired={isTagRequired}
              customFunction={(selectedTag) => {
                setAllocationProjectsTags(selectedTag.uuid);
              }}
            />
          </SlideOverInputContainer>
          <SlideOverHandleButtons
            submitLabel={
              allocationToUpdateData
                ? 'Update allocations'
                : 'Create allocations'
            }
          />
        </SlideOverFormContainer>
      </SlideOver>
      {/* Modal */}
      <Modal open={openModal} setOpen={updateOpenModal}>
        <div className="flex items-center">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 ml-2">
            Public holiday
          </h2>
        </div>

        <p className="text-sm text-gray-500">
          Are you sur you want to allocate on a public holiday ?
        </p>
        <ModalHandleButtons
          label="Continue"
          labelCancel="Cancel"
          className="bg-red-600 hover:bg-red-500"
          onClick={() => {
            updateOpenModal(false);
            updateOpenSlideOver(true);
          }}
          onClickCancel={() => updateOpenModal(false)}
        />
      </Modal>
    </SideBarLayout>
  );
}
